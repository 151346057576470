import { navigate } from "gatsby"
import React, { useEffect, useState } from "react"
import Layout from "../components/Layout"
import * as styles from "../styles/404.module.scss"
export default function NotFound() {
  const [count, setCount] = useState(30)

  useEffect(() => {
    const timer = setTimeout(() => {
      setCount(count - 1)
      if (count == 1) {
        navigate("/")
      }
    }, 1000)
    return () => clearTimeout(timer)
  }, [count])

  return (
    <Layout>
      <div className={styles.content}>
        <h1 className={styles.title}>Page Not Found</h1>
        <h2 className={styles.subtitle}>
          {" "}
          Even Wizards are fallible sometimes...
        </h2>
        <p className={styles.timer}>
          Let us teleport you somewhere more interesting! <br />
          Magicing you home in 00:00:{count}
        </p>

        <button onClick={() => navigate("/")} className={styles.button}>
          Jump straight home now
        </button>
      </div>
    </Layout>
  )
}
